import React, { useEffect, useState } from "react";
import {
  Filter,
  FloatRight,
  FormView,
  ImportFile,
  PagesHeader,
  UploadCSV,
} from "../../components/Courses";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCourseFilter, getCoursesList } from "../../appRedux/actions/courses";
import Page from "../../components/Common/Page";
import { getFreeCurrencyApi } from "../../appRedux/actions/common";

const CourseImport = () => {
  const dispatch = useDispatch();
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);

  const [viewStatus, setViewStatus] = useState("listView"); //"listView", "gridView"
  const courseList = useSelector((state) => state.courses.courseList);
  console.log({ courseList });

  useEffect(() => {
    // dispatch(getCourseFilter({}));
    // dispatch(getFreeCurrencyApi());
  }, [dispatch]);
  const data = [
    {
      Title: "",
      Platforms: "",
      Headlines: "",
      Category: "",
      Affiliation: "",
      Price: "",
      Url: "",
      CoverImage: "",
      AboutUs: "",
      WhatYouWillLearn: "",
      Level: "",
      Language: "",
      CourseType: "",
      Duration: "",
      Ratings: "",
      ReviewCount: "",
      Modules: "",
      Mode: "",
    },
  ];
  // const data = [
  //   {
  //     affiliation_image: "":"",
  //     category: "":"",
  //     course_name: "":"",
  //     price: "",
  //     price_certificate: "",
  //     duration: "",
  //     duration_per_week: "",
  //     review: "",
  //     course_type: "",
  //     affiliation: "",
  //     rating: "",
  //     platform: "",
  //     website: "",
  //     headline: "",
  //     preqrequiste: "",
  //     level: "",
  //     language: "",
  //     out_come: "",
  //     course_content: "",
  //     tags: "",
  //     image_url: "",
  //     modules: "",
  //     mode: "",
  //   },
  // ];

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(",")).join("\n");
    return `${headers}\n${rows}`;
  };

  const downloadCSV = () => {
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "course-template.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <Page title="Course">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Course Import"}
            homePageUrl="/"
            homePageText="Dashboard"
            innerPageUrl={"/course/list"}
            innerPageText={"Course List"}
            activePageText={"CSV Import"}
            floatRight={
              <button className="btn add-btn  m-r-5" onClick={downloadCSV}>
                <i className="fa fa-download" /> CSV Template
              </button>
            }
          />
          <div className="card">
            <div className="card-body">
              <ImportFile />
              {/* <UploadCSV /> */}
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default CourseImport;
